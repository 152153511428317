<template lang="html" src="./priceEstimationTable.template.vue"></template>

<script>
import { FormulaPricing } from '@cloudmanufacturingtechnologies/portal-components';

const i18nData = require('./priceEstimationTable.i18n');

export default {
  name: 'PriceEstimationTable',
  components: {
  },
  props: {
    part: {
      type: Object,
      required: true,
    },
    technology: {
      type: String,
      required: true,
    },
    material: {
      type: String,
      required: true,
    },
    pricesConfigurations: {
      type: Array,
      required: true,
    },
    priceMultiplierPercentage: {
      type: Number,
      required: true,
    },
    displayHeader: {
      type: Boolean,
      default: true,
    },
    flat: {
      type: Boolean,
      default: false,
    },
  },
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      /**
       * Main variable
       */
      error: false,
      partPrices: null,
      verifiedPart: null,
      /**
       * Custom quantity
       */
      customQuantity: null,
      priceCustomQuantity: ' - ',
      unitaryPriceCustomQuantity: ' - ',
      totalVolumeCustomQuantity: ' - cm³',
      volumePriceCustomQuantity: ' - ',
      precision: 10E2,
      /**
       * Quantities
       */
      quantities: [1, 2, 3, 4, 5, 10, 20, 50, 100],
      /**
       * Panel
       */
      expanded: 0,
    };
  },
  watch: {
    part: {
      handler: function(val, oldVal) {
        this.init();
      },
      deep: true
    },
    technology: {
      handler: function(val, oldVal) {
        this.init();
      },
    },
    material: {
      handler: function(val, oldVal) {
        this.init();
      },
    },
    pricesConfigurations: {
      handler: function(val, oldVal) {
        this.init();
      },
      deep: true
    },
    priceMultiplierPercentage: {
      handler: function(newVal, oldVal) {
        setTimeout(() => {
          if(newVal === this.priceMultiplierPercentage) {
            /**
             * No modification, we can update prices
             */
            this.updatePartPrices();
          }
        }, 100);
      },
    }
  },
  created() {
    this.init();
  },
  mounted() {},
  methods: {
    init() {
      if(!this.part || !this.part.size || !this.part.volume) {
        this.error = true;
      }else{
        this.verifiedPart = Object.assign({}, this.part);
        if(!this.verifiedPart.nestingSize) {
          this.verifiedPart.nestingSize = this.verifiedPart.size;
        }
        this.error = false;
        this.updatePartPrices();
      }
    },
    improveVolumeReadability(volumeInMillimeter) {
      let improvedValue = 0;
      switch (true) {
      case volumeInMillimeter < 1e3:
        improvedValue = `${Math.ceil(volumeInMillimeter)} mm³`;
        break;
      case volumeInMillimeter >= 1e3 && volumeInMillimeter < 1e6:
        improvedValue = `${
          Math.ceil((volumeInMillimeter / 1e3) * 1e2) / 1e2
        } cm³`;
        break;
      case volumeInMillimeter >= 1e6 && volumeInMillimeter < 1e9:
        improvedValue = `${
          Math.ceil((volumeInMillimeter / 1e6) * 1e2) / 1e2
        } dm³`;
        break;
      case volumeInMillimeter >= 1e9:
        improvedValue = `${
          Math.ceil((volumeInMillimeter / 1e9) * 1e2) / 1e2
        } m³`;
        break;
      default:
        improvedValue = 'value';
      }
      return improvedValue;
    },
    getFormula() {
      let formula = null;
      this.pricesConfigurations.forEach(priceConfiguration =>{
        if(priceConfiguration.technology === this.technology && priceConfiguration.material === this.material) {
          formula = priceConfiguration.formula;
          return;
        }
      });
      return formula;
    },
    updateCustomPrice() {
      if (
        this.customQuantity &&
        Number.parseInt(this.customQuantity) > 0 &&
        Number.parseInt(this.customQuantity) < 100000
      ) {
        /**
         * Retrieve formula
         */
        const formula = this.getFormula();
        if(formula) {
          this.unitaryPriceCustomQuantity = Math.round(this.precision * (this.priceMultiplierPercentage / 100) * FormulaPricing.getPrice(formula,this.part,Number.parseInt(this.customQuantity))) / this.precision;
          this.priceCustomQuantity = Math.round(this.precision * (this.unitaryPriceCustomQuantity * this.customQuantity)) / this.precision;
          this.totalVolumeCustomQuantity = this.improveVolumeReadability(this.part.volume * this.customQuantity);
          this.volumePriceCustomQuantity = Math.round((this.unitaryPriceCustomQuantity / (this.part.volume / 1000))) / this.precision;
        }else{
          this.priceCustomQuantity = this.$t('OutOfBound');
          this.unitaryPriceCustomQuantity = this.$t('OutOfBound');
          this.totalVolumeCustomQuantity = this.$t('OutOfBound');
          this.volumePriceCustomQuantity = this.$t('OutOfBound');
        }
      } else {
        if (this.customQuantity) {
          this.priceCustomQuantity = this.$t('OutOfBound');
          this.unitaryPriceCustomQuantity = this.$t('OutOfBound');
          this.totalVolumeCustomQuantity = this.$t('OutOfBound');
          this.volumePriceCustomQuantity = this.$t('OutOfBound');
        } else {
          this.priceCustomQuantity = ' - ';
          this.unitaryPriceCustomQuantity = ' - ';
          this.totalVolumeCustomQuantity = ' - cm³';
          this.volumePriceCustomQuantity = ' - ';
        }
      }
    },
    updatePartPrices() {
      this.partPrices = null;
      /**
         * Retrieve formula
         */
      const formula = this.getFormula();
      this.partPrices = [];
      if(formula) {
        for (const quantity of this.quantities) {
          //Call Formula
          const price = Math.round(this.precision * (this.priceMultiplierPercentage / 100) * FormulaPricing.getPrice(formula,this.part,quantity)) / this.precision;
          const currentTotalVolume = this.improveVolumeReadability(quantity * this.part.volume);
          const currentVolumePrice = Math.round(this.precision * (price / (this.part.volume / 1000))) / this.precision;
          //Push the price
          this.partPrices.push({
            quantity: quantity,
            totalPrice: Math.round(this.precision * (price * quantity)) / this.precision,
            unitaryPrice: price,
            totalVolume: currentTotalVolume,
            volumePrice: currentVolumePrice,
          });
        }
        //TODO : $emit new price -> pricingRange -> pagePart
        //this.$emit('updateComputedPrices', this);
        this.updateCustomPrice();
      }
    },
  },
};
</script>
