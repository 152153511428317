var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "priceEstimationTable"
  }, [_c('v-expansion-panels', {
    attrs: {
      "flat": _vm.flat
    },
    model: {
      value: _vm.expanded,
      callback: function callback($$v) {
        _vm.expanded = $$v;
      },
      expression: "expanded"
    }
  }, [_c('v-expansion-panel', [_vm.displayHeader ? _c('v-expansion-panel-header', [_c('v-card-title', [_c('v-icon', {
    staticClass: "mr-6",
    attrs: {
      "color": "info"
    }
  }, [_vm._v(" fas fa-euro-sign ")]), _c('span', [_vm._v(_vm._s(_vm.$t('PriceEstimations')))])], 1)], 1) : _vm._e(), _vm.partPrices ? _c('v-expansion-panel-content', [_vm.partPrices.length === 0 ? _c('div', [_c('p', {
    staticClass: "error--text"
  }, [_c('v-icon', {
    attrs: {
      "left": "",
      "large": "",
      "color": "error"
    }
  }, [_vm._v(" fas fa-exclamation-triangle ")]), _vm._v(" " + _vm._s(_vm.$t('FormulaNotConfiguredYet')) + " ")], 1)]) : _c('v-data-table', {
    attrs: {
      "items": _vm.partPrices,
      "hide-default-header": true,
      "hide-default-footer": true,
      "footer-props": {
        itemsPerPageOptions: [20, 50, 100, -1]
      }
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('thead', [_c('tr', [_c('th', {
          attrs: {
            "scope": "col"
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('Quantity')) + " ")]), _c('th', {
          staticClass: "text-center",
          attrs: {
            "scope": "col"
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('UnitaryPrice')) + " ")]), _c('th', {
          staticClass: "text-center",
          attrs: {
            "scope": "col"
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('TotalPrice')) + " ")]), _c('th', {
          staticClass: "text-center",
          attrs: {
            "scope": "col"
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('TotalVolume')) + " ")]), _c('th', {
          staticClass: "text-center",
          attrs: {
            "scope": "col"
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('VolumePrice')) + " ")])])])];
      },
      proxy: true
    }, {
      key: "body.prepend",
      fn: function fn() {
        return [_c('tr', [_c('td', [_c('v-text-field', {
          staticClass: "customQuantityField",
          attrs: {
            "disabled": !_vm.material || !_vm.technology,
            "type": "number"
          },
          on: {
            "input": _vm.updateCustomPrice
          },
          model: {
            value: _vm.customQuantity,
            callback: function callback($$v) {
              _vm.customQuantity = $$v;
            },
            expression: "customQuantity"
          }
        })], 1), _c('td', {
          staticClass: "text-center"
        }, [_vm._v(" " + _vm._s(_vm.unitaryPriceCustomQuantity) + " € ")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v(" " + _vm._s(_vm.priceCustomQuantity) + " € ")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v(" " + _vm._s(_vm.totalVolumeCustomQuantity) + " ")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v(" " + _vm._s(_vm.volumePriceCustomQuantity) + " €/cm³ ")])])];
      },
      proxy: true
    }, {
      key: "item",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('tr', [_c('td', [_vm._v("x " + _vm._s(item.quantity))]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v(" " + _vm._s(item.unitaryPrice.toFixed(2)) + " € ")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v(" " + _vm._s(item.totalPrice) + " € ")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v(" " + _vm._s(item.totalVolume) + " ")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v(" " + _vm._s(item.volumePrice) + " €/cm³ ")])])];
      }
    }], null, true)
  })], 1) : _vm._e()], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }